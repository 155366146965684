<template>
  <b-dropdown
    aria-role="list"
    class="logout-dropdown"
    v-bind="$attrs"
  >
    <template #trigger>
      <b-icon
        class="logout-trigger"
        custom-size="mdi-24px"
        icon="account-circle"
      />
    </template>
    <b-dropdown-item
      v-for="(option, oIndex) in appDropDownOptions"
      :key="'adOption' + oIndex"
      aria-role="listitem"
      @click="option.handler"
    >
      <b-icon
        :icon="option.icon"
        type="is-black"
        custom-size="mdi-22px"
        class="mr-1"
      />
      {{ option.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script >
import { useRoute } from '@/hooks/vueRouter.js';
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  dropdownOptions: {
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const props = __props;
  const signOut = () => {
    const modulelocalStorage = JSON.parse(localStorage.getItem(route.params.moduleId));
    localStorage.setItem(modulelocalStorage.auth_module_deployment_id, JSON.stringify({}));
    localStorage.setItem(route.params.moduleId, JSON.stringify({}));
    location.reload();
  };
  const defaultOptions = [{
    name: 'Sign Out',
    text: 'Sign Out',
    handler: signOut,
    icon: 'logout'
  }];
  const appDropDownOptions = computed(() => {
    return defaultOptions.map((option, index) => ({
      ...option,
      text: props.dropdownOptions?.length ? props.dropdownOptions[index].text : option.text
    }));
  });
  return {
    appDropDownOptions
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.logout-dropdown {
  .logout-trigger {
    cursor: pointer;
  }
}
</style>